video {
	/* object-fit: cover; */
	object-fit: contain;
	width: 100%;
	height: 100svh;
	position: fixed;
	z-index: -1;
	filter: brightness(90%);
}

.home-container {
	/* background: url('/images/img-home.jpg') center center/cover no-repeat; */
	min-height: 100dvh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* box-shadow: inset 0 0 0 1000px rgba(58, 1, 1, 0.2); */
	background-color: #D7CCCD ;
	/* object-fit: contain; */
	z-index: 0;
	padding: 10%;
	/* overflow-x: hidden;
	overscroll-behavior: none;
    scroll-behavior: none;
    overflow-y : scroll; */
}

.header-title {
	color: #fff;
	width: 100%;
	font-size: 5vw;
}



.home-container > p {
	margin-top: 8px;
	color: #fff;
	font-size: 32px;
}

.artistSocialsContainer {
	/* position: absolute;
	bottom: 0%; */
	margin-top: 50px;
	z-index: 0;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
    font-size: 40px;
	gap: 50%;
	padding: 24px;
}

.artistSocials {
	transition: all 0.5s ease-in-out;
	transform: scale(2.5);
	color: white;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.artistSocials:hover {
	transition: all 0.5s ease-in-out;
	transform: scale(4);
}

.latest-text {
	color: rgb(237, 74, 74);
}

.latest-cover-art-container {
	cursor: pointer;
	transition: scale 300ms ease-in-out;
	display: flex;
	height: 50%;
	width: 50%;
	flex-direction: column;
	align-items: center; 
	justify-content: center; 
	scale: calc(1);
	transition: scale 300ms ease-in-out;
  }
  
  .latest-cover-art-container:hover {
	scale: calc(1.1);
	transition: scale 300ms ease-in-out;
  }
  
  .latest-cover-art-wrapper {
	max-width: 100%;
	width: 100%;
	text-align: center; 
  }
  
  .latest-cover-art {
	max-width: 50%;
	height: auto;
  }
  


.flip-card {
	background-color: transparent;
	width: 400px;
	height: 250px;
	perspective: 1000px;
  }


  .flip-card-art {
	background-color: transparent;
	width: 350px;
	height: 350px;
	perspective: 1000px;
  }
  

  .flip-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.8s;
	transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  /* .flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
  }

  .flip-card-art:hover .flip-card-inner {
	transform: rotateY(180deg);
  }
   */



  .flip-card-front, .flip-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden; 
	backface-visibility: hidden;
	box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
  
  
  /* .flip-card-back {
	background-color: rgb(255, 255, 255);
	color: rgb(255, 74, 74);
	transform: rotateY(180deg);
  } */




  .flip-card-back {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	width: 100%;
	background-color: rgb(255, 255, 255);
	color: rgb(255, 74, 74);
	transform: rotateY(180deg);
  }



  .flip-card.flipped .flip-card-inner,
.flip-card-art.flipped .flip-card-inner {
  transform: rotateY(180deg);
}




@media screen and (max-width: 960px) {

	video {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: -1;
	}
}

@media screen and (max-width: 768px) {

	.artistSocialsContainer {
		gap: 40%,
	}

	.home-container > p {
		font-size: 30px;
	}

	video {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: -1;
	}
}


@media screen and (max-width: 450px) {

	.flip-card {
		width: 300px;
		height: 170px;
	}

	.flip-card-art {
		width: 250px;
		height: 250px;
	  }

	.artistSocialsContainer {
		gap: 40%,
	}

	.home-container > p {
		font-size: 30px;
	}

	video {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: -1;
	}
}