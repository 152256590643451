.video {
    object-fit: cover;
    height: 100dvh;
    position: fixed;
    z-index: -1;
    filter: brightness(70%);
  }
  
  .epk-container {
    z-index: -3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    overflow-x: hidden;
    overflow: hidden;
    overscroll-behavior: none;
    scroll-behavior: none;
    touch-action: none;
  }
  
  .epk-sign {
    color: #fff;
    font-size: 6vh;
    width: 100%;
    height: 8%;
  }


  .epk-inner {
    height: 100%;  
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
    margin: auto 0px;
    background-color: azure;
    background-color: rgba(129, 124, 124, 0.5);
    border-radius: 10px;
    z-index: 0;
    overflow-x: hidden;
    overflow: hidden;
    overscroll-behavior: none;
    scroll-behavior: none;
    overflow-x : hidden;
    touch-action: none;
  }


  .top-info {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
  }

  .cover-art {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cover-art > img {
    border-radius: 180px;
    width: 20vh;
  }

  .artist-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    font-size: 2.5vh;
  }

  .bio {
    display: flex;
    width: 100%;
    color: white;
    height: 10%;
    align-items: center;
    justify-content: center;
    vertical-align: middle ;
    text-align: center;
    padding: 5%;
  }

  .bio-text {
    padding: 2% 0px;
    font-size: 2vw;
  }


  .roles {
    gap: 5px;
  }

  .works {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
    /* height: 35%; */
    /* overflow: scroll; */
  }

  .works-tile {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
    padding: 1%;
  }

  .works-tile > img {
    max-width: 11vw;
  }

  .works-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 10px;
    width: 150px;
  }

  .metrics {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    height: 20%;
    width: 100%;
  }

  .metric-icon-play {
    width: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    transform: scale(2);
    transition: all .5s ease-in-out;
    cursor: pointer;
  }

  .metric-icon-play:hover {
    width: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    transform: scale(2.5);
    transition: all .5s ease-in-out;
  }

  .metric-icon {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    transform: scale(2);
    transition: all .5s ease-in-out;
    cursor: pointer;
  }

  .metric-icon:hover {
      transition: all .5s ease-in-out;
      transform: scale(2.5);
}

  .metric-block {
    display: flex;
    flex-direction: row;
  }

  .metric-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5vh;
    gap: 24px;
  }







/* 
  @media screen and (max-width: 1190px) {

    .epk-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }

    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
    }

    .works {
      display:flex;
      justify-content: space-evenly;
      align-items: center;
      align-content: center;
      justify-items: center;
      padding-left: 50px;
      padding-right: 50px;
      margin-top: 10px;
  }

  .works-info {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .works-tile > img {
    height: 100px;
    width: 100px;
  }


  .cover-art > img {
    height: 150px;
    width: 150px;
  }
  

  .metrics {
    font-size: 12px;
    padding: 40px;
  }

  .metric-icon {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  }

  }
 */



/*   
  @media screen and (max-width: 960px) {

    .epk-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }

    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
    }

  }



  
  @media screen and (max-width: 768px) {

    .epk-inner {
      vertical-align: middle;
      align-items: center;
      justify-content: center;
    }

    .artist-info {
        display: flex;
        flex-direction: column;
        font-size: 14px;
    }

    .roles {
        gap: 10px;
    }

    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
    }

    .works {
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
        justify-items: center;
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 10px;
    }

    .works-info {
      width: 0;
      height: 0;
      visibility: hidden;
    }

    .works-tile > img {
      height: 80px;
      width: 80px;
    }



    .cover-art > img {
      height: 100px;
      width: 100px;
    }
    

    .metrics {
      font-size: 18px;
    }

    .metric-icon {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    }
    

  }



    
  @media screen and (max-width: 471px) {

    .epk-inner {
      vertical-align: middle;
      align-items: center;
      justify-content: center;
    }

    .artist-info {
        display: flex;
        flex-direction: column;
        width: 50%;
        font-size: 14px;
    }

    .roles {
        gap: 10px;
    }

    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
    }

    .works {
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        align-content: center;
        justify-items: center;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 30px;
    }

    .works-info {
      width: 0;
      height: 0;
      visibility: hidden;
    }

    .works-tile > img {
      height: 3em;
      width: 3em;
    }


    .cover-art > img {
      height: 100px;
      width: 100px;
    }
    

    .metrics {
      font-size: 12px;
      padding: 40px;
      gap: 1em;
    }

    .metric-icon {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    }

    .bio {
      margin-top: 20px;
    }
    

  } */



 