.video {
    /* filter: brightness(80%); */
    -webkit-filter: brightness(80%);
    object-fit: cover;
    width: 100%;
    height: 100svh;
    position: fixed;
    z-index: -1;
  }

  
  .about-container {
    /* background: url('/images/img-about.jpg') center center/cover no-repeat; */
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
    /* object-fit: contain; */
    object-fit: cover;
    /* position: fixed;
    overscroll-behavior: none;
    scroll-behavior: none;
    overflow-x : hidden; */
  }

  .about-container-2 {
    height: 100dvh;
    display: flex;
    flex-direction: column;
  }


  .about-title {
    color: #fff;
    font-size: 17vmin;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-text {
    display: flex;
    color: white;
    padding: 0 20px;
    font-size: 3vmin;
    text-align: center;
    align-items: center;
    height: 40%;
    line-height: 30px;
    padding: 0 10%;
  }

  .inner-text {

  }

  .about-text .ella {
      color: rgb(237, 74, 74);
  }
  
  .heart {
    width: 100%;
    height: 30%;
  }

  .heart > img {
    margin-top: 10px;
    height: 17vmin;
    filter: invert(100%);
  }

  .quote {
    padding: 0 20px;
    font-size: 3vmin;
    text-align: center;
    align-items: center;
  }


  .quotes-container {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

  }
  
  @media screen and (max-width: 960px) {

  .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
    }

  }
  
  @media screen and (max-width: 768px) and ( max-height: 1028px) {


  .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
    }


  }

