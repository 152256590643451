.video {
    object-fit: cover;
    /* object-fit: contain; */
    width: 100%;
    height: 100svh;
    position: fixed;
    z-index: -1;
    filter: brightness(90%);
  }
  
  .contact-container {
    /* background: url('/images/img-contact.jpg') center center/cover no-repeat; */
    min-height: 100svh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    align-self: center;
    align-content: center;
    box-shadow: inset 0 0 0 1000px rgba(1, 1, 1, 0.5);
    object-fit: contain;
  }
  
  .contact-container > h1 {
    color: #fff;
    font-size: 100px;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 40px;
    height: 100%;
  }
  
  @media screen and (max-width: 960px) {
    .contact-container > h1 {
      font-size: 70px;
    }

    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
    }
  }
  
  @media screen and (max-width: 768px) {
    
    .contact-container > h1 {
      font-size: 70px;
    }
  
    .contact-container > p {
      font-size: 30px;
    }

    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
    }


    .mgmt {
      font-size: .8em;
    }

  }