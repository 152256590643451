

a {
  text-decoration: none;
  color: white;
  opacity:1;
  font-size: 1.5em;
  font-weight: 400;
  transition: 200ms;
}
a:hover {
  opacity:0.5;
}
ul {
  padding: 0;
  list-style-type: none;
}


.content {
  height: 100%;
  width: 100%;
  top: 0;
  background-color: transparent;
  position: absolute;
  overflow: hidden;
}


nav {
  background-color: transparent;
  height: 65px;
}

.menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  /* top: 25px;
  left: 25px; */
  margin-top: 25px;
  margin-left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

.menuToggle input
{
  display: flex;
  margin-top: -15px;
  margin-left: -10px;
  width: 50px;
  height: 50px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 3;
  padding: 10px;
}

.menuToggle span
{
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 2;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

.menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

.menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: white;
}
.menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

.menu
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: absolute;
  width: 100vw;
  height: 101vh;
  margin: -30px 0 0 -25px;
  padding: 50px;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px) ;
  /* backdrop-filter: blur(12px); */
  z-index: 1;
  touch-action: none;
}

.menu li
{
  padding: 10px 0;
  transition-delay: 2s;
  font-size: 50px;
}

.menuToggle input:checked ~ ul
{
  transform: none;
}

