.gallery-container {
  height: 100dvh;
  background-color: rgb(228, 228, 215);
  display: flex;
  flex-direction: row;
}

.mySwiper {
  /* padding: 100px; */
  z-index: 0;
}

/* .gallery-title {
  position: absolute;
  width: 100%;
  color: white;
  bottom: 90%;
  font-size: 50px;
  z-index: 1;
} */

.gallery-title {
  visibility: hidden;
  width: 0;
  height: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-wrapper {
  width: 100%;
}

.swiper-slide {
  /* text-align: center;
  font-size: 18px;
  background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-photo{
  height: 100%;
  max-width: 100%;
  user-select: none;
}

.swiper-button-next {
  color: black;
}

.swiper-button-prev {
  color: black;
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none;
  color: rgb(77, 77, 77);
}


  @media screen and (max-width: 900px) {

    .swiper-slide img {
      width: 100%;
    }

  }

  @media screen and (max-width: 768px) {

    .swiper-slide img {

      width: 100%;

    }

    .left-side,.right-side {
      visibility: hidden;
    }

    .gallery-title {
      position: absolute;
      width: 100%;
      color: white;
      bottom: 90%;
      font-size: 50px;
      visibility: visible;
      z-index: 1;
    }
    
  }

  
  @media screen and (max-width: 550px) {

    .swiper-slide img {

      width: 100%;

    }
    
  }