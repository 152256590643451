@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

#root, html, body {
  margin: 0;
  padding: 0;
  font-family: Bebas Neue;
  width: 100dvw;
  height: 100dvh;
  /* overflow: hidden; */
}

@media (max-width: 768px) {

  #root, html, body {
      overflow: hidden;
  }

}
